import { environment as env } from './shared_env';

export const environment = {
    ...env,
    production: false,
    envAbbr: 'sb',
    backendApiUrl: 'http://localhost:65108',
    clientUrl: 'http://localhost:4200',
    testEnv: true,
};
