import { Component, Inject, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { RouterService } from 'lib/services/router.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { CategoryService } from 'lib/services/category/category.service';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'cms-gated-content-post.component';

@Component({
    selector: 'app-cms-gated-content-post',
    templateUrl: './cms-gated-content-post.component.html',
    styleUrls: ['./cms-gated-content-post.component.scss'],
})
export class CmsGatedContentPostComponent extends ExxComComponentClass implements OnInit {
    categoryService: CategoryService;
    cmsService: CmsService;

    routerService: RouterService;
    entry: any = { rows: [] };
    getWebpImg: (src: string) => string;
    constructor(@Inject('environment') e: any, ca: CategoryService, c: CmsService, r: RouterService, w: WebpService) {
        super({
            dependencies: {
                categoryService: ca,
                cmsService: c,
                environment: e,
                routerService: r,
            },
        });
        this.getWebpImg = (src: string) => w.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            const urlComponent = get(this.routerService, 'url.component', '');
            const res = await this.cmsService.getEntries('gated_content', {
                query: { url: '/' + urlComponent },
                references: [
                    'modular_blocks.gated_heading_block.gated_heading_block',
                    'modular_blocks.image_and_text.image_and_text',
                    'modular_blocks.image_and_text.full_width_image_and_text',
                ],
            });
            const rows = get(res, '[0].modular_blocks', []);
            this.entry = { rows: rows };
        } catch (err) {
            console.error(...new ExxComError(551122, scriptName, err).stamp());
        }
    }
}
