import { Component, OnInit } from '@angular/core';
import { CmsService } from 'lib/services/cms.service';

@Component({
    selector: 'app-header-contactbar',
    templateUrl: './header-contactbar.component.html',
    styleUrls: ['./header-contactbar.component.scss'],
})
export class HeaderContactbarComponent implements OnInit {
    entry: any = {};

    constructor(private cmsService: CmsService) {}

    async ngOnInit() {
        this.entry = await this.cmsService.getEntry('header');
    }
}
