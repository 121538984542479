<ng-container *ngIf="!isEmpty(get(multipleColumnsRow, 'multi_column_v2', ''))">
    <div *ngFor="let row of get(multipleColumnsRow, 'multi_column_v2.multi_column_v2', [])">
        <section id="rows" *ngIf="!isEmpty(get(row, 'columns'))" class="container">
            <div class="multi-column-container">
                <div class="multi-column-title">
                    <h2 class="text-center title">
                        {{ get(row, 'multicolumn_content_title', '') }}
                    </h2>
                    <p class="multi-column-subtitle my-0 text-center">
                        {{ get(row, 'subtitle', '') }}
                    </p>
                </div>
                <div class="multi-columns-sub-container" [ngClass]="'column-' + get(row, 'columns_number')">
                    <div *ngFor="let column of get(row, 'columns', '')" class="multi-column" [class.card]="get(column, 'enable_card', '')">
                        <div class="image-container">
                            <img
                                *ngIf="!get(column, 'image_enlargement') && !get(column, 'image_link.routerLink', '')"
                                [src]="getWebpImg(get(column, 'image.href', ''))"
                                [alt]="get(column, 'image.title', '')"
                            />

                            <a
                                *ngIf="get(column, 'image_link.routerLink', '') && !get(column, 'image_enlargement')"
                                [href]="get(column, 'image_link.routerLink', '')"
                            >
                                <img [src]="getWebpImg(get(column, 'image.href', ''))" [alt]="get(column, 'image.title', '')" />
                            </a>
                        </div>

                        <app-image-zoom
                            [image]="getWebpImg(get(column, 'image.href', ''))"
                            [altText]="get(column, 'image.title', '')"
                            style="width: 100%"
                            *ngIf="get(column, 'image_enlargement')"
                        >
                            <div class="image-container">
                                <img
                                    *ngIf="get(column, 'image_enlargement')"
                                    [src]="getWebpImg(get(column, 'image.href', ''))"
                                    [alt]="get(column, 'image.title', '')"
                                />
                            </div>
                        </app-image-zoom>

                        <div class="text-components-container">
                            <h3
                                *ngIf="get(column, 'column_title_group', '')"
                                class="column-title w-100"
                                [ngStyle]="{
                                    'text-align': get(column, 'column_title_group.column_title_alignment', '')
                                }"
                            >
                                {{ get(column, 'column_title_group.column_title', '') }}
                            </h3>
                            <p class="column-copy" *ngIf="get(column, 'column_copy_group.enable_copy', '')">
                                {{ get(column, 'column_copy_group.column_copy', '') }}
                            </p>
                            <button
                                *ngIf="
                                    get(column, 'column_button.enable_button', '') &&
                                    get(column, 'column_button.marketo_form_selection.form_selection', '') == 'no_form'
                                "
                                class="button-primary button-base d-flex align-items-center w-100 learn-more-button"
                                [routerLink]="get(column, 'column_button.routerLink', '')"
                                (click)="
                                    bootstrapMarketoService.openBootstrapMarketoDialog(get(row, 'marketo_form.id', ''), 'edit', {
                                        title: dataTitle,
                                        url: dataUrl
                                    })
                                "
                            >
                                {{ get(column, 'column_button.link.title', '') }}
                                <mat-icon>chevron_right</mat-icon>
                            </button>

                            <ng-container *ngIf="get(column, 'column_button.marketo_form_selection.form_selection', '') !== 'no_form'">
                                <button-component
                                    data-cy="marketo-form"
                                    *ngIf="get(column, 'column_button.marketo_form_selection.form_selection', '') == 'default_form'"
                                    (click)="
                                        bootstrapMarketoService.openBootstrapMarketoDialog(solutionID, 'edit', {
                                            title: dataTitle,
                                            url: dataUrl
                                        })
                                    "
                                    [buttonOptions]="{
                                        text: column.column_button.link.title,
                                        type: 'primary',
                                        iconPosition: 'right',
                                        iconName: 'chevron-right',
                                        class: 'w-100 mt-1'
                                    }"
                                ></button-component>
                                <button-component
                                    data-cy="marketo-form"
                                    *ngIf="get(column, 'column_button.marketo_form_selection.form_selection', '') == 'other_form'"
                                    (click)="
                                        bootstrapMarketoService.openBootstrapMarketoDialog(
                                            get(column, 'marketo_form_selection.marketo_forms.id', ''),
                                            'edit',
                                            {
                                                title: dataTitle,
                                                url: dataUrl
                                            }
                                        )
                                    "
                                    [buttonOptions]="{
                                        text: column.column_button.link.title,
                                        type: 'primary',
                                        iconPosition: 'right',
                                        iconName: 'chevron-right',
                                        class: 'w-100 mt-1'
                                    }"
                                ></button-component>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container>
<hr *ngIf="get(multipleColumnsRow, 'multi_columns.split_line', '')" class="m-0 mb-3" />
