<!-- !TODO Create a custom directive or component to accept a url and return the correct href or routerLink attribute depending on path. -->
<section>
    <div *ngFor="let row of get(logosRow, 'logos.logos', [])">
        <div class="d-flex flex-column container logos-container">
            <div *ngIf="get(row, 'partnership', false)" class="partners-top-title fx-row pb-2 col-xs-12 p-0">
                <img src="https://images.exxactcorp.com/img/exx/misc/x-emblem.svg" alt="logo" />
                <div class="d-flex flex-column justify-content-center pl-3">
                    <h2 class="pb-1 mb-0">Partnerships</h2>
                </div>
            </div>
            <div *ngIf="!get(row, 'partnership', false) && get(row, 'logos_title', '')" class="category-top-title d-flex justify-content-center pb-2">
                <h2 *ngIf="get(row, 'logos_title', '')" class="col-md-7 text-center">
                    {{ get(row, 'logos_title', '') }}
                </h2>
            </div>
            <div class="col-sm-12 p-0">
                <div class="logo-icon-facets">
                    <div class="grid partial-grid brand-container" [class.partner-container]="get(row, 'partnership', false)">
                        <div *ngFor="let logo of get(row, 'logos', [])" class="g-col-4 g-col-md-2 d-flex flex-column justify-content-center">
                            <ng-container *ngIf="get(logo, 'logo_link', '').length > 0">
                                <a *ngIf="logo.logo_link.startsWith('http')" class="logo-link" [href]="logo.logo_link">
                                    <img
                                        *ngIf="get(logo, 'image.href', '') && get(logo, 'image.href', '')"
                                        [src]="getWebpImg(logo.image.href)"
                                        [alt]="logo.image.title"
                                    />
                                </a>
                                <a *ngIf="logo.logo_link.startsWith('/')" class="logo-link" [routerLink]="logo.logo_link">
                                    <img
                                        *ngIf="get(logo, 'image.href', '') && get(logo, 'image.href', '')"
                                        [src]="getWebpImg(logo.image.href)"
                                        [alt]="logo.image.title"
                                    />
                                </a>
                            </ng-container>

                            <img
                                *ngIf="get(logo, 'image.href', '') && get(logo, 'logo_link', '') == ''"
                                [src]="getWebpImg(logo.image.href)"
                                [alt]="logo.image.title"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<hr *ngIf="get(logosRow, 'logos.split_line', '')" class="m-0 mb-3" />
