<section
    [ngStyle]="{
        'background-color': get(imageAndTextRow.image_and_text, 'exxcom_color_picker', ''),
        'background-repeat': 'no-repeat',
        'margin-bottom': get(imageAndTextRow.image_and_text, 'exxcom_color_picker', '').length > 0 ? '24px' : ''
    }"
>
    <div *ngIf="!isEmpty(get(imageAndTextRow, 'image_and_text.text_block', ''))" class="d-flex flex-row container flex-wrap pb-2 image-text">
        <div *ngFor="let row of get(imageAndTextRow, 'image_and_text.text_block', [])" class="w-100">
            <div class="w-100" *ngIf="row.text_block" [innerHTML]="get(row, 'text_block', '') | safe: 'html'"></div>
        </div>
    </div>

    <ng-container *ngFor="let row of get(imageAndTextRow, 'image_and_text.image_and_text', [])">
        <div
            class="image-text grid partial-grid container"
            *ngIf="
                isEmpty(get(imageAndTextRow, 'image_and_text.image_and_text[0].accordion', false)) &&
                isEmpty(get(imageAndTextRow, 'image_and_text.image_and_text[0].button_row', false))
            "
        >
            <div
                *ngIf="row.image.href"
                class="g-col-lg-6 g-col-12 d-flex flex-column align-items-center justify-content-center"
                [class.order-1]="row.style === 'Image on the right' && !isNarrow"
            >
                <img *ngIf="row.enable_image_enlargement != true" [src]="getWebpImg(row.image.href)" [alt]="row.image.title" />
                <app-image-zoom *ngIf="row.enable_image_enlargement == true" [image]="row.image.href" [altText]="'test'"></app-image-zoom>
            </div>
            <div
                class="g-col-lg-6 g-col-12 d-flex flex-column justify-content-center text-section"
                [class.order-0]="row.style === 'Image on the right' && !isNarrow"
            >
                <h5 *ngIf="row.label">
                    {{ row.label }}
                </h5>
                <h2 *ngIf="row.header">
                    {{ row.header }}
                </h2>
                <div *ngIf="row.text" [innerHTML]="row.text | safe: 'html'"></div>
                <div class="d-flex flex-row mt-3" *ngIf="get(row, 'link.title', '')">
                    <!-- Marketo Form Button -->

                    <ng-container *ngIf="get(row, 'marketo_form_selection.form_selection', '') !== 'no_form'">
                        <button-component
                            *ngIf="get(row, 'marketo_form_selection.form_selection', '') == 'default_form'"
                            data-cy="marketo-form"
                            (click)="
                                bootstrapMarketoService.openBootstrapMarketoDialog(solutionID, 'edit', {
                                    title: dataTitle,
                                    url: dataUrl
                                })
                            "
                            [buttonOptions]="{
                                text: get(row, 'link.title', ''),
                                type: 'secondary',
                                iconPosition: 'right',

                                iconName: 'chevron-right',
                                class: 'mt-2'
                            }"
                        ></button-component>
                        <button-component
                            *ngIf="get(row, 'marketo_form_selection.form_selection', '') == 'other_form'"
                            data-cy="marketo-form"
                            (click)="
                                bootstrapMarketoService.openBootstrapMarketoDialog(get(row, 'marketo_form_selection.marketo_forms.id', ''), 'edit', {
                                    title: dataTitle,
                                    url: dataUrl
                                })
                            "
                            [buttonOptions]="{
                                text: get(row, 'link.title', ''),
                                type: 'secondary',
                                iconPosition: 'right',

                                iconName: 'chevron-right',
                                class: 'mt-2'
                            }"
                        ></button-component>
                    </ng-container>
                    <button-component
                        *ngIf="get(row, 'marketo_form_selection.form_selection', '') == 'no_form' && get(row, 'link.routerLink', '')"
                        data-cy="marketo-form"
                        (click)="buttonLinkRouter(get(row, 'link.routerLink', '/'))"
                        role="link"
                        [buttonOptions]="{
                            text: get(row, 'link.title', ''),
                            type: 'secondary',
                            iconPosition: 'right',

                            iconName: 'chevron-right',
                            class: 'mt-2'
                        }"
                    ></button-component>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Dynamic Image Button Row  -->
    <div *ngIf="!isEmpty(get(imageAndTextRow, 'image_and_text.image_and_text[0].button_row', false))" class="container" id="dynamic-buttons">
        <div class="grid">
            <!-- Images -->
            <h2 *ngIf="isNarrow" class="g-col-12">
                {{ get(imageAndTextRow, 'image_and_text.image_and_text[0].header', '') }}
            </h2>
            <div
                class="g-col-12 g-col-lg-6 d-flex flex-row align-items-center"
                [class.justify-content-center]="isNarrow"
                id="button-row-image-container"
            >
                <app-image-zoom
                    id="button-row-image"
                    class="h-100"
                    *ngIf="imageAndTextRow?.image_and_text?.image_and_text[0]?.enable_image_enlargement == true"
                    [image]="
                        currentButtonRowImage !== ''
                            ? currentButtonRowImage
                            : imageAndTextRow.image_and_text.image_and_text[0].button_row[0].button_image.href
                    "
                    [altText]="
                        currentButtonRowImage !== ''
                            ? currentButtonRowImageAlt
                            : imageAndTextRow.image_and_text.image_and_text[0].button_row[0].button_image.title
                    "
                    [@imgChange]="currentButtonRowImage"
                ></app-image-zoom>

                <img
                    *ngIf="imageAndTextRow?.image_and_text?.image_and_text[0]?.enable_image_enlargement !== true"
                    id="button-row-image"
                    class="h-100"
                    #buttonRowImageElement
                    [src]="
                        currentButtonRowImage !== ''
                            ? currentButtonRowImage
                            : imageAndTextRow.image_and_text.image_and_text[0].button_row[0].button_image.href
                    "
                    [alt]="
                        currentButtonRowImage !== ''
                            ? currentButtonRowImageAlt
                            : imageAndTextRow.image_and_text.image_and_text[0].button_row[0].button_image.title
                    "
                    [@imgChange]="currentButtonRowImage"
                />
            </div>

            <!-- Buttons -->
            <div
                *ngIf="!get(imageAndTextRow, 'image_and_text.image_and_text[0].text')"
                id="button-row-and-text-container"
                class="g-col-12 g-col-lg-6 d-flex flex-column justify-content-center"
            >
                <h2 *ngIf="!isNarrow">{{ get(imageAndTextRow, 'image_and_text.image_and_text[0].header', '') }}</h2>
                <div class="button-row-container">
                    <div class="button-row-margins">
                        <button
                            class="button button-gradient button-row-button"
                            *ngFor="let button of get(imageAndTextRow, 'image_and_text.image_and_text[0].button_row', []); let i = index"
                            id="{{ 'button-' + i }}"
                            [class.active]="'button-' + i === activeButtonId || (i === 0 && activeButtonId === '')"
                            (click)="setActiveButtonAndImage(i, button.button_image.href)"
                        >
                            {{ button.button_text }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Dynamic accordion -->
    <div
        *ngIf="!isEmpty(get(imageAndTextRow, 'image_and_text.image_and_text[0].accordion', false))"
        class="container-padless d-flex flex-row flex-wrap"
        [class.flex-row-reverse]="get(imageAndTextRow, 'image_and_text.image_and_text[0].style', '') === 'Image on the right'"
        [class.justify-content-center]="isNarrow"
    >
        <div class="accordion-image-container col-md-6" *ngIf="!isNarrow">
            <img
                class="accordion-image"
                #accordionImage
                [src]="currentImage !== '' ? currentImage : imageAndTextRow?.image_and_text?.image_and_text[0]?.accordion[0]?.accordion_image?.href"
            />
        </div>
        <div [id]="'dynamicAccordion-' + uniqueIndex" class="accordion col-xs-12 col-lg-6 dynamic-accordion-container">
            <div class="image-and-text-accordion-text">
                <h5 class="accordion-label mb-base">
                    {{ get(imageAndTextRow, 'image_and_text.image_and_text[0].label', []) }}
                </h5>
                <h2 class="accordion-title">{{ get(imageAndTextRow, 'image_and_text.image_and_text[0].header', []) }}</h2>
                <div
                    *ngIf="get(imageAndTextRow, 'image_and_text.image_and_text[0].text', [])"
                    [innerHTML]="get(imageAndTextRow, 'image_and_text.image_and_text[0].text', '') | safe: 'html'"
                ></div>
            </div>
            <div
                class="accordion-item"
                *ngFor="let accordion of get(imageAndTextRow, 'image_and_text.image_and_text[0].accordion', []); index as i"
                id="{{ 'accordion-item-' + uniqueIndex + i }}"
                (click)="setAccordion(i)"
            >
                <div class="accordion-header" id="{{ 'headingImageAndText-' + uniqueIndex + i }}">
                    <div class="accordion-button" [class.collapsed]="i !== currentAccordion" type="button">
                        <label class="check-icon" for="rd1"
                            ><div class="horizontal"></div>
                            <div *ngIf="i !== currentAccordion" class="vertical"></div
                        ></label>
                        <span>{{ accordion?.accordion_title }}</span>
                    </div>
                </div>

                <div id="{{ 'collapseImageAndText-' + uniqueIndex + i }}" class="accordion-collapse collapse" [class.show]="i === currentAccordion">
                    <div class="accordion-body">
                        <div *ngIf="isNarrow" class="accordion-image-container col-xs-12 col-lg-6 px-0">
                            <img
                                class="accordion-image"
                                #accordionImage
                                [src]="
                                    currentImage !== ''
                                        ? currentImage
                                        : imageAndTextRow?.image_and_text?.image_and_text[0]?.accordion[0]?.accordion_image?.href
                                "
                            />
                        </div>
                        <div [innerHTML]="accordion?.accordion_copy | safe: 'html'"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<hr *ngIf="get(imageAndTextRow, 'image_and_text.split_line', '')" class="m-0 mb-3" />
