<div class="hero-banner text-center pb-5" [style.background-image]="'url(' + entry.top_background_image?.href + ')'">
  <div class="container-fluid d-flex justify-content-center">
    <div class="py-5 px-2 col-md-6 text-center" [class.font-light-theme]="entry.font_color_light_theme">
      <h1>{{ entry.top_title }}</h1>
      <p>{{ entry.top_paragraph }}</p>
    </div>
  </div>

  <lib-marketo-form [formId]="formId"></lib-marketo-form>
</div>
