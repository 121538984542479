<div id="cms-gated-content-post">
  <app-gated-content-header></app-gated-content-header>

  <div class="fx-row-container-reverse-sm fx-wrap p-2">
    <div class="col-sm-6">
      <img *ngIf="entry.image?.href" [src]="entry.image.href" [alt]="entry.image.title" />
    </div>

    <div class="col-sm-6 pt-4">
      <h2>{{ entry.bottom_title }}</h2>
      <div [innerHTML]="entry.bottom_description"></div>
    </div>
  </div>

  <app-cms-partial-category-top [data]="entry"></app-cms-partial-category-top>
</div>
