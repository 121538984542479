<div id="category" (window:resize)="onResize()" style="position: relative">
    <app-cms-partial-category-top [data]="cmsPartialCategoryTopData" data-cy="category-section"></app-cms-partial-category-top>

    <div *ngIf="category?.websitePageType != 'Landing' && !isLandingPage" id="category-main">
        <div id="category-header">
            <!-- BREADCRUMB -->

            <div id="category-breadcrumb">
                <span *ngIf="category.breadcrumb.part1">
                    <span> {{ category.breadcrumb.part1.name }} </span>
                </span>
                <span *ngIf="category.breadcrumb.part2">
                    &nbsp;/&nbsp;
                    <a
                        [routerLink]="'/category/' + category.breadcrumb.part2.href"
                        class="link-gray-inverted"
                        [class.highlight]="currentCategoryBreadcrumbPart == category.breadcrumb.part2.name"
                    >
                        {{ category.breadcrumb.part2.name }}
                    </a>
                </span>
                <span *ngIf="category.breadcrumb.part3">
                    &nbsp;/&nbsp;
                    <a
                        [routerLink]="'/category/' + category.breadcrumb.part3.href"
                        class="link-gray-inverted"
                        [class.highlight]="currentCategoryBreadcrumbPart == category.breadcrumb.part3.name"
                    >
                        {{ category.breadcrumb.part3.name }}
                    </a>
                </span>
            </div>
        </div>

        <section id="category-body" class="fx-row fx-wrap">
            <app-category-no-results
                *ngIf="category.isInitialized && category.products.length == 0 && category.facets.active.length == 0"
                [category]="category"
            ></app-category-no-results>

            <!-- FACETS -->

            <div
                *ngIf="category.products.length > 0 || category.facets.active.length != 0"
                id="category-body-column-1"
                data-cy="exx-side-bar-filter"
                class="col-lg-1_5 pb-4"
                #facetsNarrowMenu
                [class.open]="category.facets.menuOpen"
                (wheel)="preventMobileDefaultScroll($event)"
            >
                <div id="category-facets-container">
                    <!-- FACETS HEADER -->

                    <div id="category-facets-header" class="fx-col fx-col-v-bottom">
                        <span class="fx-row fx-row-v-center fx-row-h-space-between">
                            <span>Filters</span>
                            <button class="link-blue pr-1" (click)="clearAllFilters(); $event.stopPropagation()" data-cy="sidebar-clear-all-button">
                                Clear All
                            </button>
                        </span>
                    </div>

                    <!-- FACETS CONTROLS ACTIVE -->

                    <div *ngIf="category.isFiltered" id="category-facets-controls" data-cy="sidebar-filter-container">
                        <div
                            *ngFor="let activeFacet of category.facets.active"
                            class="category-facets-controls-facet button-tag fx-row fx-row-h-space-between fx-row-v-center"
                            (click)="filter(activeFacet.group, activeFacet.facet); $event.stopPropagation()"
                        >
                            <span>{{ activeFacet.group.label }}: {{ activeFacet.facet.label }}</span>
                            <mat-icon>close</mat-icon>
                        </div>
                    </div>

                    <!-- FACETS -->

                    <div id="category-facets" data-cy="exx-category-facet-group-parent">
                        <div class="category-facet-group" *ngFor="let facetGroup of category.facets.groups" data-cy="exx-category-facet-group">
                            <div
                                class="category-facet-header fx-row fx-row-h-space-between my-2"
                                (click)="toggleFacetGroupCollapsed(facetGroup.index)"
                                data-cy="exx-category-facet-filter-header"
                            >
                                <div class="category-facet-label">{{ facetGroup.label }}</div>
                                <mat-icon class="category-facet-expander" [class.collapsed]="getFacetCollapsed(facetGroup.index)"
                                    >expand_more</mat-icon
                                >
                            </div>
                            <div
                                [id]="'category-facet-group-container-' + facetGroup.index"
                                class="category-facet-group-container fx-col"
                                [attr.data-cy-is-facet-collapsed]="getFacetCollapsed(facetGroup.index)"
                                [class.collapsed]="getFacetCollapsed(facetGroup.index)"
                                [class.maximized]="getFacetMaximized(facetGroup.index) && facetGroup.totalFacets > 5"
                            >
                                <div
                                    *ngFor="let facet of facetGroup.facets; index as i"
                                    class="category-facet fx-row fx-row-v-center pb-1"
                                    data-cy="category-facet-filter-item"
                                    [id]="'facet-' + facetGroup.index + '-' + i"
                                    (click)="filter(facetGroup, facet)"
                                    [class.active-facet]="facet.isActive"
                                >
                                    <div class="category-facet-checkbox image-checkbox-1" [class.checked]="facet.isActive"></div>
                                    <span class="category-facet-text" [class.active]="facet.isActive">{{ facet.label }} ({{ facet.count }})</span>
                                </div>
                            </div>

                            <!--
                Changing the Max and Min Number of Displayed Filters
                - Set the number next to facetGroup.totalFacets to the minimum
                  number of filters to display.
                - In the Sass file, near .category-facet-maximizer-container, set
                  the $number-facets-minimized variable to the same.
                - Also in the Sass file, set the $number-facets-maximized value to
                  the maximum number of filters to display.
              -->

                            <div
                                class="category-facet-maximizer-container fx-row fx-row-h-center"
                                [class.show]="facetGroup.totalFacets > 5 && !getFacetCollapsed(facetGroup.index)"
                                [class.pad-bottom]="facetGroup.totalFacets <= 5 && !getFacetCollapsed(facetGroup.index)"
                            >
                                <div
                                    class="category-facet-maximizer"
                                    [class.show]="
                                        facetGroup.totalFacets > 5 && !getFacetMaximized(facetGroup.index) && !getFacetCollapsed(facetGroup.index)
                                    "
                                    (click)="toggleFacetGroupMaximized(facetGroup.index)"
                                >
                                    See more
                                </div>
                                <div
                                    class="category-facet-maximizer"
                                    [class.show]="
                                        facetGroup.totalFacets > 5 && getFacetMaximized(facetGroup.index) && !getFacetCollapsed(facetGroup.index)
                                    "
                                    (click)="toggleFacetGroupMaximized(facetGroup.index)"
                                >
                                    See less
                                </div>
                            </div>
                        </div>

                        <span
                            class="facets-footer"
                            [class.show]="category?.facets.menuOpen"
                            (click)="toggleNarrowFilterMenu()"
                            (wheel)="preventMobileDefaultScroll($event)"
                        >
                            <a class="button-primary"> Done <mat-icon>chevron_right</mat-icon> </a>
                        </span>
                    </div>
                </div>

                <div *ngIf="isNarrow" id="category-body-column-1-narrow-spacer"></div>

                <!-- BANNER LEFT -->

                <div *ngIf="category.banners.left" id="category-banners-left" [innerHTML]="category.banners.left"></div>
            </div>

            <!-- PRODUCTS -->

            <div *ngIf="category.products.length > 0" id="category-body-column-2" class="col-lg-4_5">
                <div id="category-products-container" class="fx-col">
                    <!-- PRODUCTS: HEADER -->

                    <div id="category-products-header" class="fx-row fx-row-h-space-between fx-row-v-bottom">
                        <!-- PRODUCTS TOTAL -->

                        <div id="category-count">
                            {{ category.totalProducts }} Item{{ category.totalProducts == 1 ? '' : 's' }}
                            {{ category.searchTerm ? ' for "' + category.searchTerm + '"' : '' }}
                        </div>

                        <!-- FACETS NARROW MENU TOGGLE -->

                        <div
                            id="category-facets-narrow-menu-toggle"
                            data-cy="category-facets-mobile-filter"
                            #facetsNarrowMenuToggle
                            class="col-xs-6 pl-0 pr-1"
                        >
                            <button class="button fx-row fx-row-h-space-between fx-row-v-center" (click)="toggleNarrowFilterMenu()">
                                <span class="fx-row fx-row-h-space-between fx-row-v-center">Filter <mat-icon svgIcon="sliders"></mat-icon></span>
                            </button>
                        </div>

                        <!-- SORT -->

                        <div id="category-sort" class="fx-row fx-row-v-center col-xs-6 pl-1 pr-0" #sortMenu>
                            <div id="category-sort-menu">
                                <button
                                    id="category-sort-button"
                                    (click)="sortMenuOpen = !sortMenuOpen"
                                    class="d-flex align-items-center justify-content-between"
                                    [class]="sortMenuOpen ? 'open button mat-elevation-z2' : 'button'"
                                >
                                    {{ category.sorting.selectedLabel }}
                                    <mat-icon>expand_more</mat-icon>
                                </button>
                                <div
                                    id="category-sort-menu-options"
                                    class="fx-col"
                                    [class]="sortMenuOpen ? 'open fx-col mat-elevation-z2' : 'fx-col'"
                                >
                                    <a
                                        *ngFor="let option of category.sorting.options; let i = index"
                                        class="category-sort-menu-option"
                                        (click)="sort(i); sortMenuOpen = false"
                                    >
                                        {{ option.label }}
                                    </a>
                                </div>
                            </div>

                            <div id="sort-view-buttons" class="fx-row fx-row-v-center fx-row-h-center">
                                <a class="list-view-icon" rel="nofollow"
                                    ><mat-icon
                                        id="mat-icon-list"
                                        [ngClass]="numberOfColumns == 1 ? 'active' : ''"
                                        (click)="toggleActive(1)"
                                        svgIcon="list"
                                    ></mat-icon
                                ></a>
                                <a class="details-view-icon" rel="nofollow"
                                    ><mat-icon
                                        id="mat-icon-details"
                                        [ngClass]="numberOfColumns == 3 ? 'active' : ''"
                                        (click)="toggleActive(3)"
                                        svgIcon="grid"
                                    ></mat-icon
                                ></a>
                                <a class="grid-view-icon" rel="nofollow"
                                    ><mat-icon
                                        id="mat-icon-grid"
                                        [ngClass]="numberOfColumns == 4 ? 'active' : ''"
                                        (click)="toggleActive(4)"
                                        svgIcon="grid-9"
                                    ></mat-icon
                                ></a>
                            </div>
                        </div>
                    </div>

                    <!-- PRODUCTS: NARROW -->
                    <div id="category-products-narrow" class="fx-row fx-wrap" data-cy="category-product-item-container-narrow">
                        <div *ngFor="let product of category.products" class="category-product col-xs-12">
                            <div class="fx-row fx-row-v-center">
                                <div class="pr-1">
                                    <div class="category-product-image-container fx-row fx-row-h-center fx-row-v-center mr-1">
                                        <a [routerLink]="product.urlComponent">
                                            <img class="category-product-image-narrow" [src]="getWebpImg(product?.imageUrl)" [alt]="product?.name" />
                                        </a>
                                    </div>
                                </div>

                                <div>
                                    <a
                                        class="category-product-name-narrow truncate-ellipsis mb-1"
                                        [routerLink]="product.urlComponent"
                                        [innerHTML]="product.name"
                                        data-cy="mobile-category-product-link"
                                    ></a>
                                    <span *ngIf="!product.pricing.showPrice" class="category-product-message-narrow">MPN: {{ product.mpn }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- PRODUCTS WIDE: VARIABLE COLUMNS -->
                    <div
                        *ngIf="numberOfColumns"
                        id="category-products-wide"
                        class="fx-row fx-wrap"
                        data-cy="category-product-item-container"
                        [class.grid-container]="numberOfColumns === 4"
                        [class.row-class]="category.products.length < 4"
                    >
                        <div
                            *ngFor="let product of category.products; index as i"
                            data-cy="exx-desktop-category-product"
                            [class]="getContainerClassFromColumns(numberOfColumns, i, category?.products?.length)"
                        >
                            <div [class]="getBorderClassFromColumns(numberOfColumns, i)" class="w-100">
                                <!-- category product image multi -->
                                <div *ngIf="numberOfColumns != 1" [class]="numberOfColumns == 3 ? 'image-outer mb-2' : 'image-outer'">
                                    <div class="category-product-image-container-wide fx-row fx-row-h-center fx-row-v-center">
                                        <img
                                            class="category-product-image category-product-image-wide"
                                            [src]="getWebpImg(product?.imageUrl)"
                                            [alt]="product?.name"
                                        />
                                        <button-component
                                            class="category-product-quick-view"
                                            data-cy="exx-open-product-quick-view-button-desktop"
                                            (click)="openProductQuickView(product.urlComponent)"
                                            [buttonOptions]="{
                                                text: 'Quick View',
                                                type: 'secondary'
                                            }"
                                        ></button-component>
                                    </div>
                                </div>

                                <!-- category product image single -->
                                <div *ngIf="numberOfColumns == 1" class="image-outer col-lg-4 col-xl-4">
                                    <div class="category-product-image-container-wide fx-row fx-row-h-center fx-row-v-center">
                                        <img
                                            class="category-product-image category-product-image-wide"
                                            [src]="getWebpImg(product?.imageUrl)"
                                            [alt]="product?.name"
                                        />
                                        <button-component
                                            class="category-product-quick-view"
                                            data-cy="exx-open-product-quick-view-button-desktop"
                                            (click)="openProductQuickView(product.urlComponent)"
                                            [buttonOptions]="{
                                                text: 'Quick View',
                                                type: 'secondary'
                                            }"
                                        ></button-component>
                                    </div>
                                </div>

                                <div
                                    [class]="
                                        numberOfColumns == 1
                                            ? 'category-product-text-container col-lg-4 col-xl-4'
                                            : numberOfColumns == 4
                                            ? 'category-product-text-container pb-2'
                                            : 'category-product-text-container'
                                    "
                                >
                                    <div
                                        [class]="
                                            numberOfColumns == 4
                                                ? 'category-product-name-small truncate-ellipsis mt-2 mb-1'
                                                : 'category-product-name truncate-ellipsis mb-1'
                                        "
                                    >
                                        <a [routerLink]="product?.urlComponent" [innerHTML]="product?.name"></a>
                                    </div>
                                    <div
                                        *ngIf="!product?.pricing?.showPrice"
                                        class="category-product-sales-message"
                                        [class.mb-3]="numberOfColumns < 4"
                                    >
                                        MPN: {{ product?.mpn }}
                                    </div>
                                    <div
                                        *ngIf="numberOfColumns < 4 && product.highlights"
                                        id="product-listing-highlights"
                                        class="fx-row fx-row-h-left"
                                    >
                                        <div class="highlight pl-2" [innerHTML]="getHighlight(product?.highlights) | safe: 'html'"></div>
                                    </div>
                                </div>
                                <div *ngIf="numberOfColumns == 1" class="fx-col fx-col-h-left product-quote pl-3 col-lg-4 col-xl-4">
                                    <button-component
                                        *ngIf="product?.pricing.buttonText == 'Send Inquiry'"
                                        class="w-100"
                                        (click)="getProductIdOpenModal(product)"
                                        [buttonOptions]="{
                                            text: 'Get a Quote',
                                            type: 'primary',
                                            iconPosition: 'right',
                                            iconName: 'chevron-right',
                                            class: 'w-100'
                                        }"
                                    ></button-component>
                                    <div *ngIf="product?.customizable">
                                        <a class="button-secondary" (click)="getProductIdOpenModal(product)">
                                            Customize now <mat-icon>chevron_right</mat-icon>
                                        </a>
                                    </div>
                                    <div class="pt-3">Our experts are here to help.</div>
                                    <div class="fx-row contact-row">
                                        <a href="/Contact-Us" class="link-blue fx-row fx-row-v-center pr-2"
                                            >Contact an expert <mat-icon>chevron_right</mat-icon></a
                                        >
                                        <a href="/Partners" class="link-blue fx-row fx-row-v-center"
                                            >Become a reseller <mat-icon>chevron_right</mat-icon></a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- PRODUCTS: FOOTER -->

                    <div id="category-products-footer" class="fx-row fx-row-h-center pb-3">
                        <!-- PAGINATION -->

                        <app-category-pagination></app-category-pagination>
                    </div>
                </div>
            </div>
        </section>

        <div id="category-footer" class="fx-row">
            <div *ngIf="category?.banners?.bottom" id="category-banners-bottom" [innerHTML]="category.banners.bottom"></div>
        </div>
    </div>
</div>
