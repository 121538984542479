import { Component, OnInit } from '@angular/core';
import { CmsService } from 'lib/services/cms.service';
import { RouterService } from 'lib/services/router.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'cms-gated-content-header.component';

@Component({
    selector: 'app-gated-content-header',
    templateUrl: './gated-content-header.component.html',
    styleUrls: ['./gated-content-header.component.scss'],
})
export class GatedContentHeaderComponent implements OnInit {
    entry: any = {};
    formId: string;

    constructor(
        private cmsService: CmsService,
        private routerService: RouterService
    ) {}

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('gated_content');
            this.formId = `mktoForm_${this.entry.marketo_form.id}`;
        } catch (err) {
            console.error(...new ExxComError(912832, scriptName, err).stamp());
        }
    }
}
