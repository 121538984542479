import { Component, Input } from '@angular/core';
import { WebpService } from 'lib/services/webp.service';

@Component({
    selector: 'app-configurations-grid',
    templateUrl: './configurations-grid.component.html',
    styleUrls: ['./configurations-grid.component.scss'],
})
export class ConfigurationsGridComponent {
    @Input() configurationsRow: any;
    getWebpImg: (src: string) => string;
    constructor(webpService: WebpService) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
