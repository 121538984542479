import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { ApiService } from 'lib/services/api.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MessageService } from 'lib/services/message.service';
import { GrecaptchaService } from 'lib/services/google/grecaptcha.service';
import { GtmService } from 'lib/services/google/gtm.service';
import { isBrowser, timestamp, wait } from 'lib/tools';
import { MarketoService } from 'lib/services/marketo.service';
import { MarketoFormThankYouComponent } from '../marketo-form-thank-you/marketo-form-thank-you.component';
import { assign, startsWith, get, isEmpty, debounce } from 'lodash';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CmsService } from 'lib/services/cms.service';
import { Router } from '@angular/router';
import { RouterService } from 'lib/services/router.service';

const scriptName = 'marketo-form.component';
let environment: any;

const defaultAfterSubmitMessage = {
    text: ['<strong>Thank you for your inquiry!</strong><br>', 'A member of our team will get back to you shortly.'].join(''),
    css: {
        display: 'inline-block',
        margin: '20px 0 12% 20px',
    },
};

@Component({
    selector: 'lib-marketo-form',
    templateUrl: './marketo-form.component.html',
    styleUrls: ['./marketo-form.component.scss'],
})
export class MarketoFormComponent implements OnChanges, OnInit {
    @Input() afterSubmitMessage: any = {
        text: defaultAfterSubmitMessage.text,
        css: defaultAfterSubmitMessage.css,
    };
    @Input() formId: string = '';
    @Input() interest: string = '';
    @Input() quantity: string | number = '';
    @Input() configuratorData: any;
    @Input() saveConfiguratorInformation: any;
    @Input() configuratorSystemPrice: any;
    @Input() resetAfterSubmit: boolean = false;
    @Input() showSuccessMessage: boolean = true;

    @Output() formEmitter: any = new EventEmitter<any>();
    @Output() formSubmittedEmitter: any = new EventEmitter<any>();
    @Output() reloadEventEmitter: any = new EventEmitter<any>();

    @ViewChild('formElem') formElem: ElementRef;
    @ViewChild('formWrapper') formWrapper: ElementRef;
    @ViewChild('afterSubmitWrapper') afterSubmitWrapper: ElementRef;

    private form: any;

    private blacklist: string[] = [
        'testing@example.com',
        'roshanj26@gmail.com',
        'p69258174@gmail.com',
        'Mekellecheferdaouss68@gmail.com',
        'mcastillo.olv@gmail.com',
        'hgh@hot.com',
        'pricebutlera@gmail.com',
    ];

    showAfterSubmitMessage: boolean = false;
    showSpinner: boolean = true;
    entry: any = {};
    contentType: string = 'inquiry';
    bsModalRef: BsModalRef;
    sentToSales: boolean = true;

    constructor(
        @Inject('environment') e: any,
        private apiService: ApiService,
        private grecaptchaService: GrecaptchaService,
        private gtmService: GtmService,
        private marketoService: MarketoService,
        public messageService: MessageService,
        private modalService: BsModalService,
        private cmsService: CmsService,
        private router: Router,
        private routerService: RouterService
    ) {
        environment = e;
        // In the future we want to create an endpoint to support this. Check documents for existing matching document and only create if
        // there is no match
        this.createInquiry = debounce(this.createInquiry, 10000, {
            leading: true,
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes.formId && changes.formId.currentValue) {
                this.load();
            }
        } catch (err) {
            console.error(...new ExxComError(399393, scriptName, err).stamp());
        }
    }

    async ngOnInit() {
        try {
            this.setEntry();
            this.showSuccessMessage = this.resetAfterSubmit ? false : this.showSuccessMessage;
        } catch (err) {
            console.error(...new ExxComError(288838, scriptName, err).stamp());
        }
    }

    async load() {
        try {
            const formId = this.formId.split('_')[1];
            this.form = await this.marketoService.load(formId);
            this.applyClasses();
            this.defineValidationHandler();
        } catch (err) {
            console.error(...new ExxComError(829922, scriptName, err).stamp());
        }
    }
    // add custom conditional statements to return corresponding entry/row/etc
    async setEntry() {
        try {
            if (startsWith(this.router.url, '/eBooks') || startsWith(this.router.url, '/Whitepapers') === true) {
                this.entry = await this.cmsService.getPage('gated_content');

                return this.entry;
            }
        } catch (err) {
            console.error(...new ExxComError(992112, scriptName, err).stamp());
        }
    }
    // sets current content type
    // type will need to be added to pages/partials/rows etc on content stack that require custom thank you messages
    async populateContentType() {
        try {
            if (!isEmpty(this.entry)) {
                this.contentType = get(this.entry, 'type[0]._content_type_uid', 'inquiry');
                return this.contentType;
            } else {
                return this.contentType;
            }
        } catch (err) {
            console.error(...new ExxComError(992312, scriptName, err).stamp());
        }
    }

    private defineValidationHandler() {
        try {
            let asyncFieldsAdded = false;
            this.form.onValidate(async (isValid: boolean) => {
                this.form.submittable(isValid);
                if (!isValid) {
                    return;
                }
                if (this.isBlacklisted()) {
                    this.form.submittable(false);
                    this.formWrapper.nativeElement.classList.add('hide');
                    this.showAfterSubmitMessage = true;
                    return;
                } else if (asyncFieldsAdded) {
                    this.form.submittable(true);
                } else {
                    this.form.submittable(false);
                    this.defineSuccessHandler();
                    await this.addHiddenFields();
                    asyncFieldsAdded = true;
                    this.form.submit();
                    const formValues = this.form.vals();
                    console.log('validate');
                    console.log(formValues);

                    this.gtmService.trackMarketoFormSubmission(this.formId.split('_')[1]);
                    this.gtmService.trackGoals(this.formId.split('_')[1]);
                    this.formEmitter.emit(this.form);
                    await this.createInquiry(formValues);
                }
            });
        } catch (err) {
            console.error(...new ExxComError(277399, scriptName, err).stamp());
        }
    }

    async createInquiry(form: Object) {
        try {
            return this.apiService.post('marketo/inquiries/create', form);
        } catch (err) {
            console.error(...new ExxComError(111119, scriptName, err).stamp());
        }
    }

    private async sendEmail() {
        const formValues = this.form.vals();
        const values = assign(
            {
                subject: this.sentToSales ? 'Exxact Configuration Inquiry' : 'Exxact Configuration Download',
                sentToSales: this.sentToSales,
                configUrl: this.saveConfiguratorInformation,
            },
            formValues
        );
        const res = await this.apiService.post('email/configurator', values);
        if (!res.success) {
            console.error(timestamp(), 'configurator.component', 839564, res);
            this.messageService.addLocal('configurator', res.error && res.error.message);
        } else {
            // this.formSubmitted = true;
            await wait('100ms');
        }
    }

    private defineSuccessHandler() {
        try {
            this.form.onSuccess(() => {
                const formData = this.form.getValues();
                console.log('success');
                console.log(formData);
                const customerData = {
                    customerFirstName: formData.FirstName,
                    customerLastName: formData.LastName,
                    customerEmail: formData.Email,
                    companyName: formData.Company,
                    jobTitle: formData.Title,
                    phoneNumber: formData.Phone,
                    message: formData.commentCapture,
                    quantity: formData.systemQuantity,
                };

                // Construct total price string based on quantity
                if (formData.systemQuantity) {
                    const currencyPipe = new CurrencyPipe('en-US');
                    let totalPrice = currencyPipe.transform(
                        (parseFloat(this.configuratorSystemPrice.replace(/[$,]/g, '')) * parseFloat(formData.systemQuantity)).toFixed(2)
                    );
                    if (parseFloat(formData.systemQuantity) > 3) totalPrice = totalPrice + '+';

                    this.form.addHiddenFields({
                        configuratorSystemPriceTotal: totalPrice,
                    });
                }

                if (this.showSuccessMessage) {
                    if (this.formId == 'mktoForm_2612') {
                        if (!(<HTMLInputElement>document.getElementById('sendtoSales')).checked) {
                            this.sentToSales = false;
                        }
                    }

                    this.formWrapper.nativeElement.classList.add('hide');

                    // Need to delay showing afterSubmitMessage for pdf download forms
                    if (this.formId == 'mktoForm_2612') {
                        this.showSpinner = true;
                        setTimeout(() => {
                            this.showAfterSubmitMessage = true;
                            this.showSpinner = false;
                        }, 5000);
                    } else {
                        this.showAfterSubmitMessage = true;
                    }

                    this.populateContentType();
                    if (this.contentType !== 'inquiry') {
                        this.openModalCustomMessage(this.contentType);
                    }

                    if (this.configuratorData) {
                        this.sendEmail();
                    }
                }
                this.formSubmittedEmitter.emit(customerData);
                if (this.resetAfterSubmit) {
                    this.reset();
                }
                return false;
            });
        } catch (err) {
            console.error(...new ExxComError(987277, scriptName, err).stamp());
        }
    }

    private async addHiddenFields() {
        try {
            this.form.addHiddenFields({
                grecaptchamarketo: await this.grecaptchaService.getToken(`marketoForm_${this.formId}`),
            });
            if (this.interest) {
                this.form.addHiddenFields({ productInterest: this.interest });
            }
            if (this.quantity) {
                this.form.addHiddenFields({ productQuantity: this.quantity });
            }

            if (this.configuratorData) {
                this.form.addHiddenFields({
                    configuratorData: this.configuratorData,
                });
            }

            if (this.saveConfiguratorInformation) {
                this.form.addHiddenFields({
                    configuratorInformation: this.saveConfiguratorInformation,
                });
            }

            if (this.configuratorSystemPrice) {
                this.form.addHiddenFields({
                    configuratorSystemPrice: this.configuratorSystemPrice,
                });
            }
        } catch (err) {
            console.error(...new ExxComError(399922, scriptName, err).stamp());
        }
    }

    private async applyClasses() {
        try {
            if (!isBrowser()) {
                return;
            }
            const formWrapper = this.formWrapper.nativeElement;
            const formElem = formWrapper.getElementsByTagName('form')[0];
            if (!formElem) {
                return;
            }
            const button = formElem.getElementsByClassName('mktoButton')[0];
            if (!button) {
                return;
            }
            // Removed in order to override default and custom styles;
            // may be possible to keep it after removing the custom styles that are defined in Marketo
            button.classList.remove('mktoButton');
            button.classList.add('button-primary');
            button.classList.add('button-base');
            formWrapper.classList.remove('hide');
            this.showSpinner = false;
        } catch (err) {
            console.error(...new ExxComError(299922, scriptName, err).stamp());
        }
    }

    private isBlacklisted() {
        try {
            const formData = this.form.getValues();
            if (this.blacklist.includes(formData.Email)) {
                return true;
            } else return false;
        } catch (err) {
            console.error(...new ExxComError(299932, scriptName, err).stamp());
        }
    }

    // calls ngx-bootstrap modal to render component based on contentType
    openModalCustomMessage(contentType) {
        try {
            if (contentType === 'gated_content_type') {
                this.bsModalRef = this.modalService.show(MarketoFormThankYouComponent, { class: 'thank-you-message modal-dialog-centered' });
            }
        } catch (err) {
            console.error(...new ExxComError(909231, scriptName, err).stamp());
        }
    }

    reset() {
        try {
            this.afterSubmitWrapper.nativeElement.classList.add('hide');
            this.showSpinner = true;
            this.reloadEventEmitter.emit();
            const formWindow = document.querySelector('.modal') as HTMLElement;
            const backdrop = document.querySelector('.modal-backdrop') as HTMLElement;
            if (formWindow) formWindow.style.display = 'none';
            if (backdrop) backdrop.style.display = 'none';
            window.location.reload();
        } catch (err) {
            console.error(...new ExxComError(499922, scriptName, err).stamp());
        }
    }

    getBaseUrl() {
        // can't submit this locally
        if (this.routerService.baseUrl == 'http://localhost:65008') return 'http://localhost:65007';
        else return this.routerService.baseUrl;
    }
}
