<button
    [class]="buttonOptions.class + ' button'"
    [class.primary]="buttonOptions.type === 'primary'"
    [class.secondary]="buttonOptions.type === 'secondary'"
    [class.tertiary]="buttonOptions.type === 'tertiary'"
    [class.facet]="buttonOptions.type === 'facet'"
    [class.graph-swap]="buttonOptions.type === 'graph-swap'"
    [class.tiny-primary]="buttonOptions.type === 'tiny-primary'"
    [class.tiny-use-case]="buttonOptions.type === 'tiny-use-case'"
    [class.tag]="buttonOptions.type === 'tag'"
    [disabled]="buttonOptions.disabled"
    [routerLink]="buttonOptions.internalLink"
    [class.no-min-width]="buttonOptions.unsetWidth"
>
    <ng-container *ngIf="buttonOptions.iconPosition == 'left'">
        <div
            class="icon-left"
            [ngClass]="{
                'icon-size-small': buttonOptions.iconSize === 'small',
                'icon-size-medium': buttonOptions.iconSize === 'medium',
                'icon-size-large': buttonOptions.iconSize === 'large'
            }"
        >
            <i-feather
                class="button-icon"
                [name]="buttonOptions?.iconName"
                [ngClass]="{
                    'icon-size-small': buttonOptions.iconSize === 'small',
                    'icon-size-medium': buttonOptions.iconSize === 'medium',
                    'icon-size-large': buttonOptions.iconSize === 'large',
                    'stroke-width-small': buttonOptions.iconStrokeWidth === 'small',
                    'stroke-width-medium': buttonOptions.iconStrokeWidth === 'medium',
                    'stroke-width-large': buttonOptions.iconStrokeWidth === 'large'
                }"
            ></i-feather>
        </div>
    </ng-container>
    <ng-container *ngIf="buttonOptions.externalLink">
        <a [href]="buttonOptions.externalLink">
            <div class="button-text">{{ buttonOptions.text }}</div></a
        >
    </ng-container>
    <div *ngIf="!buttonOptions.externalLink" class="button-text">{{ buttonOptions.text }}</div>
    <ng-container *ngIf="buttonOptions.iconPosition == 'right'">
        <div
            class="icon-right"
            [ngClass]="{
                'icon-size-small': buttonOptions.iconSize === 'small',
                'icon-size-medium': buttonOptions.iconSize === 'medium',
                'icon-size-large': buttonOptions.iconSize === 'large'
            }"
        >
            <i-feather
                class="button-icon"
                [name]="buttonOptions?.iconName"
                [ngClass]="{
                    'icon-size-small': buttonOptions.iconSize === 'small',
                    'icon-size-medium': buttonOptions.iconSize === 'medium',
                    'icon-size-large': buttonOptions.iconSize === 'large',
                    'stroke-width-small': buttonOptions.iconStrokeWidth === 'small',
                    'stroke-width-medium': buttonOptions.iconStrokeWidth === 'medium',
                    'stroke-width-large': buttonOptions.iconStrokeWidth === 'large'
                }"
            ></i-feather>
        </div>
    </ng-container>
</button>
