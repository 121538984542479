import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import {
    Mail,
    ChevronRight,
    ChevronLeft,
    ChevronUp,
    ChevronDown,
    Check,
    Heart,
    Tool,
    X,
    PlusCircle,
    MinusCircle,
    MessageSquare,
    Maximize,
} from 'angular-feather/icons';

const icons = {
    Mail,
    ChevronRight,
    ChevronLeft,
    ChevronUp,
    ChevronDown,
    Heart,
    Tool,
    X,
    PlusCircle,
    MinusCircle,
    MessageSquare,
    Maximize,
    Check,
};

@NgModule({
    imports: [FeatherModule.pick(icons)],
    exports: [FeatherModule],
})
export class IconsModule {}
