import { BreakpointObserver } from '@angular/cdk/layout';
import { ElementRef, HostListener, ViewChild, Input, OnDestroy, Directive, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AccountService } from 'lib/services/account/account.service';
import { CategoryQuickViewDialogComponent } from 'lib/components/category/category-quick-view-dialog/category-quick-view-dialog.component';
import { CartService } from 'lib/services/cart/cart.service';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';
import { copyText, isBrowser } from 'lib/tools';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { debounce, get } from 'lodash';
import { GtmService } from 'lib/services/google/gtm.service';
import { MarketoDialogComponent } from 'lib/components/marketo-dialog/marketo-dialog.component';
import { ProductImageDialogComponent } from 'lib/components/product/product-image-dialog/product-image-dialog.component';
import { SessionService } from 'lib/services/session.service';
import { SwiperSlide } from 'lib/classes/swiper-slide.class';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { MetaService } from 'lib/services/meta.service';
import { decode } from 'html-entities';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BootstrapCategoryQuickViewDialogComponent } from '../category/bootstrap-category-quick-view-dialog/bootstrap-category-quick-view-dialog.component';

const scriptName = 'product-listing-component.class';

@Directive()
export class ProductListingComponentClass extends ExxComComponentClass implements OnDestroy {
    // Host listeners

    @HostListener('window:scroll', []) onWindowScroll() {
        if (!this.isBrowser || !this.isNarrow) {
            return;
        }
        // In chrome and some browser scroll is given to body tag
        const scrollPos: number = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        const footerPos = document.documentElement.scrollHeight - 676; // 676 is the height of footer in narrow view
        this.isVisible = scrollPos < footerPos;
    }

    // Data inputs

    @Input() isQuickView: boolean = false;
    @Input() isSingleSearchView: boolean = false;
    @Input() product: WebstoreProduct;

    // View children

    @ViewChild('narrowQuantity') narrowQuantity: ElementRef;
    @ViewChild('wideQuantity') wideQuantity: ElementRef;

    // Dependencies

    accountService: AccountService;
    bsModalRef?: BsModalRef;
    bsModalService: BsModalService;
    public options: ModalOptions;
    breakpointObserver: BreakpointObserver;
    cartService: CartService;
    configuratorService: ConfiguratorService;
    gtmService: GtmService;
    metaService: MetaService;
    productService: WebstoreProductService;
    sessionService: SessionService;
    dialog: MatDialog;
    productType: string;

    // Properties: public

    isBrowser: boolean = isBrowser();
    isNarrow: boolean = false;
    isOnWishlist: boolean = false;
    isVisible: boolean = true;
    showAddedToCartMessage: boolean = false;
    showWishlistMessage: boolean = false;
    productImgSlides: SwiperSlide[] = [];

    constructor({ dependencies }) {
        super({ dependencies });
        if (get(dependencies, 'breakpointObserver')) {
            this.initBreakpointListener();
        }
        this.openProductQuickView = debounce(this.openProductQuickView, 500, {
            leading: true,
            trailing: false,
        });
    }

    init() {
        try {
            if (isBrowser()) {
                this.dialog.afterAllClosed.subscribe(() => {
                    const swiperNavs = document.querySelectorAll('.swiper-button-disabled');
                    swiperNavs.forEach((navButton) => {
                        navButton.classList.remove('swiper-button-disabled');
                    });
                });
            }
            this.initImgSlides();
            if (!this.environment.hasCheckout) {
                return;
            }
            this.initIsOnWishlist();
            if (!this.isSingleSearchView) {
                this.metaService.addProductMetadata(this.product);
            }
            this.productType = this.product?.manufacturer == 'SabrePC' || this.product?.topCategory == 'Supermicro' ? 'Solution' : 'Component';
        } catch (err) {
            console.error(...new ExxComError(839885, scriptName, err).stamp());
        }
    }

    initBreakpointListener() {
        try {
            this.breakpointObserver.observe(['only screen and (min-width: 1100px)']).subscribe((result: any) => {
                this.isNarrow = !result.matches;
            });
        } catch (err) {
            console.error(...new ExxComError(650051, scriptName, err).stamp());
        }
    }

    private initImgSlides() {
        try {
            const images: string[] = get(this.product, 'imageUrls', []);
            const slides: SwiperSlide[] = images.map((src: string) => {
                return new SwiperSlide({
                    heading: this.product.name,
                    imageUrl: src,
                    linkUrl: null,
                    secondHeading: this.product.name,
                });
            });
            this.productImgSlides = slides;
        } catch (err) {
            console.error(...new ExxComError(893499, scriptName, err).stamp());
        }
    }

    private initIsOnWishlist() {
        try {
            if (!this.sessionService.isValidLocalSessionData() || this.sessionService.isGuestSession()) {
                return;
            }
            this.accountService.account.wishlist.lines.forEach((line: any) => {
                if (line.product._id == this.product._id) {
                    this.isOnWishlist = true;
                }
            });
        } catch (err) {
            console.error(...new ExxComError(893493, scriptName, err).stamp());
        }
    }

    openBootstrapMarketoDialog(marketoFormId: string, product: any) {
        try {
            const initialState = {
                data: {
                    action: 'display',
                    marketoFormId: marketoFormId,
                    quantity: this.isNarrow ? get(this, 'narrowQuantity.nativeElement.value', '') : get(this, 'wideQuantity.nativeElement.value', ''),
                    interest: `${product?.mpn} | ${product?.category} | ${this.environment.urls.base}${product.urlComponent}`,
                },
            };
            this.bsModalRef = this.bsModalService.show(MarketoDialogComponent, {
                animated: true,
                class: 'modal-marketo-form modal-dialog-centered',
                initialState,
            });
            this.bsModalRef.content.closeBtnName = 'Close';
        } catch (err) {
            console.error(...new ExxComError(969695, scriptName, err).stamp());
        }
    }

    openProductImageSwiperDialog(images: any[], initialSlide: number = 0) {
        try {
            if (this.isQuickView) {
                return;
            }
            const imageClone = [];
            // have to do this instead of just passing images, because modifying original URL affects product listing
            images.forEach((image) => {
                const clone = JSON.parse(JSON.stringify(image));
                if (clone.imageUrl && clone.imageUrl.indexOf('med_lrg') !== -1) {
                    const split = image.imageUrl.split('med_lrg');
                    clone.imageUrl = split[0] + 'large' + split[1];
                }
                imageClone.push(clone);
            });
            this.dialog.open(ProductImageDialogComponent, {
                width: this.isNarrow ? '100vw' : '690px',
                height: this.isNarrow ? '60vh' : '750px',
                data: {
                    images: imageClone,
                    isNarrow: this.isNarrow,
                    isBrowser: this.isBrowser,
                    initialSlide,
                },
            });
        } catch (err) {
            console.error(...new ExxComError(674324, scriptName, err).stamp());
        }
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.bsModalService.show(template, {
            class: 'modal-lg product-preview product-preview-modal modal-dialog-centered',
        });
    }

    async addToCart(product: any, quantity: HTMLInputElement) {
        try {
            if (this.showAddedToCartMessage) {
                return;
            }
            this.showAddedToCartMessage = true;
            // case for searchspring product from search results
            if (product && product._id == '') {
                product = await this.productService.getProductByUrlComponent(product.urlComponent);
            }
            setTimeout(() => (this.showAddedToCartMessage = false), 1000);
            this.cartService.add(product._id, quantity.value);
            this.gtmService.trackAddToCart(product, quantity.value);
        } catch (err) {
            console.error(...new ExxComError(723001, scriptName, err).stamp());
        }
    }

    toggleWishlist() {
        try {
            if (!this.isBrowser || !this.product._id) {
                return;
            }
            if (!this.sessionService.isValidLocalSessionData() || this.sessionService.isGuestSession()) {
                this.showWishlistMessage = true;
                setTimeout(() => (this.showWishlistMessage = false), 3000);
            } else {
                this.isOnWishlist = !this.isOnWishlist;
                if (this.isOnWishlist) {
                    this.accountService.account.wishlist.add(this.product._id);
                } else {
                    this.accountService.account.wishlist.remove(this.product._id);
                }
            }
        } catch (err) {
            console.error(...new ExxComError(929832, scriptName, err).stamp());
        }
    }

    copyLink(event: any) {
        this.isBrowser && copyText(event, window.location.href);
    }

    getHighlight(input: any) {
        try {
            if (!isBrowser()) {
                return;
            }
            return decode(input).replace('<ul>', `<ul style ='font-size:.875em'>`);
        } catch (err) {
            console.error(...new ExxComError(907233, scriptName, err).stamp());
        }
    }

    async openQuickView(urlComponent: string) {
        try {
            const product = await this.productService.getProductByUrlComponent(urlComponent);
            if (!product) {
                return;
            }
            this.dialog.open(CategoryQuickViewDialogComponent, {
                height: 'auto',
                maxHeight: '750px',
                data: {
                    action: 'display',
                    type: 'quickView',
                    product: product,
                },
            });
        } catch (err) {
            console.error(...new ExxComError(901900, scriptName, err).stamp());
        }
    }

    async openProductQuickView(urlComponent: string) {
        try {
            const product = await this.productService.getProductByUrlComponent(urlComponent);

            const initialState = {
                data: {
                    action: 'display',
                    type: 'quickView',
                    product: product == null ? this.product : product,
                },
            };

            if (this.product !== null) {
                this.bsModalRef = this.bsModalService.show(BootstrapCategoryQuickViewDialogComponent, {
                    animated: true,
                    class: 'modal-lg modal-quick-view modal-dialog-centered',
                    initialState,
                });
                this.bsModalRef.content.closeBtnName = 'Close';
            } else {
                throw new Error('this product seems to be missing');
            }
        } catch (err) {
            console.error(...new ExxComError(922201, scriptName, err).stamp());
        }
    }

    decodeHTMLEntities(input: string) {
        if (!/&[a-zA-Z0-9#]+;/.test(input)) {
            // If there are no HTML entities, return the input as is
            return input;
        }
        const parser = new DOMParser();
        const decodedDocument = parser.parseFromString(input, 'text/html');

        return decodedDocument.documentElement.textContent;
    }

    manipulateHighlights(highlights) {
        // Decode HTML entities in the highlights string
        highlights = this.decodeHTMLEntities(highlights);
        const newHighlights = highlights.split('</li>');
        for (let i = 0; i < newHighlights.length - 1; i++) {
            newHighlights[i] = this.strip(newHighlights[i]);
            newHighlights[i] = newHighlights[i].split(': ') as any;
        }
        newHighlights.pop();

        return newHighlights;
    }

    manipulateHighlightsNoCategory(highlights: string) {
        highlights = this.decodeHTMLEntities(highlights);
        const newHighlights = highlights.split('</li>');
        for (let i = 0; i < newHighlights.length - 1; i++) {
            newHighlights[i] = this.strip(newHighlights[i]);
            const colonIndex = newHighlights[i].indexOf(':');
            if (colonIndex !== -1) {
                newHighlights[i] = newHighlights[i].substring(colonIndex + 1).trim();
            }
        }
        newHighlights.pop();
        return newHighlights;
    }

    strip(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    }

    ngOnDestroy() {
        try {
            this.metaService.removeProductMetadata();
        } catch (err) {
            console.error(...new ExxComError(136342, scriptName, err).stamp());
        }
    }
}
